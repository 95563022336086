import errorIcon from "@goalteller/app-kit/assets/svg/error-boy.svg";
import HeadWrapper from "@goalteller/app-kit/components/head/NxHeadWrapper";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";

import styles from "./404.module.css";

export default function ErrorPage() {
  const router = useRouter();
  return (
    <>
      <HeadWrapper title="Page not found - SahiDecision" />
      <div className={styles.root}>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Page Not Found</div>
          <div className={styles.subTitle}>Error 404</div>
          <div>
            <div className={clsx(styles.subTitle, styles.text)}>
              The page you are looking for doesn&apos;t exist.
            </div>
            <div className={clsx(styles.subTitle, styles.text)}>
              Head over to{" "}
              <Button
                className={clsx(styles.subTitle, styles.text, styles.button)}
                onClick={() => void router.push("/")}
                variant="borderless"
              >
                sahidecision.com
              </Button>{" "}
              to choose a new direction.
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <Image alt="error-icon" src={errorIcon} width={400} />
        </div>
      </div>
    </>
  );
}
